import React, { useEffect, useState } from "react";
import Logo from "../../assets/png/icon-mark.png";
import Setting from "../../assets/svg/Setting.svg";
import Plus from "../../assets/svg/AddIcon.svg";
import { useLocation, useNavigate, useRoutes } from "react-router-dom";
import Camera from "../../assets/svg/Camera.svg";
import Chat from "../../assets/svg/Chat.svg";
import { TiFlashOutline } from "react-icons/ti";
import { Tooltip } from "react-tooltip";
import ResultsIcon from "../../assets/svg/ResultsIcon.svg";
import { FaRegUser } from "react-icons/fa6";

const Items = [
  {
    name: "DASHBOARD",
    width: "30px",
    height: "30px",
    route: "/",
    icon: false,
    img: Logo,
    new: false,
    tooltipContent: "Dashboard",
    id: "sidebar-dashboard-icon",
  },
  {
    name: "QUICK_BITES",
    width: "22px",
    height: "22px",
    new: true,
    route: "/quick-bites",
    icon: true,
    img: (
      <div className="text-[26px]">
        {" "}
        <TiFlashOutline />{" "}
      </div>
    ),
    tooltipContent: "Quick Bites",
    id: "sidebar-quick-bites-icon",
  },
  {
    name: "CREATORS",
    width: "32px",
    height: "32px",
    route: "/creators",
    icon: true,
    img: (
      <div className="text-[22px]">
        {" "}
        <FaRegUser className={``} />{" "}
      </div>
    ),
    new: false,
    tooltipContent: "Professionals",
    id: "sidebar-settings-icon",
  },
  {
    name: "ACCOUNT_SETTING",
    width: "22px",
    height: "22px",
    route: "/account/meeting",
    icon: false,
    img: Setting,
    new: false,
    tooltipContent: "Settings",
    id: "sidebar-settings-icon",
  },
];

const MobileItems = [
  {
    name: "DASHBOARD",
    width: "18px",
    height: "18px",
    route: "/",
    icon: false,
    img: Logo,
    new: false,
    // tooltipContent: "Dashboard",
    // id: "sidebar-dashboard-icon",
  },
  {
    name: "QUICK_BITES",
    width: "18px",
    height: "18px",
    new: false,
    route: "/quick-bites",
    icon: true,
    img: (
      <div className="text-[20px]">
        {" "}
        <TiFlashOutline />{" "}
      </div>
    ),
    // tooltipContent: "Quick Bites",
    // id: "sidebar-quick-bites-icon",
  },
  {
    name: "CREATORS",
    width: "26px",
    height: "26px",
    route: "/creators",
    icon: true,
    img: (
      <div className="text-[18px]">
        {" "}
        <FaRegUser className={``} />{" "}
      </div>
    ),
    new: false,
    // tooltipContent: "Reports",
    // id: "sidebar-settings-icon",
  },
  {
    name: "MEETINGS",
    width: "18px",
    height: "18px",
    route: "/account/meeting",
    img: Camera,
    icon: false,
    new: false,
    // tooltipContent: "Your Meetings",
    // id: "sidebar-meeting-icon",
  },
  {
    name: "DISCUSSIONS",
    width: "18px",
    height: "18px",
    route: "/account/discussion",
    img: Chat,
    icon: false,
    new: false,
    // tooltipContent: "Your Discussions",
    // id: "sidebar-discussion-icon",
  },
];

let quickBitesActiveTabs = ["quick-bites", "quiz", "questions"];
let reportActiveTabs = ["weekly-performance"];

export default function Sidebar(props) {
  const [activeTab, setActiveTab] = useState();
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    getScreenStyle();
    return () => {};
  });

  function handleNavigator(route) {
    navigate(route);
  }

  let getScreenStyle = () => {
    if (getScreenWidth() > 768) {
      return handleActiveTab();
    } else {
      return handleMobileActiveTab();
    }
  };

  let handleMobileActiveTab = () => {
    let accountRoute = location.pathname.includes("/account");
    if (quickBitesActiveTabs.includes(location.pathname.split("/")[1])) {
      setActiveTab("QUICK_BITES");
    } else if (
      reportActiveTabs.includes(location.pathname.split("/")[1]) ||
      location.pathname === "/"
    ) {
      setActiveTab("DASHBOARD");
    } else if (accountRoute) {
      if (location.pathname.includes("/account/meeting")) {
        setActiveTab("MEETINGS");
        return;
      }
      setActiveTab("DISCUSSIONS");
    } else if (
      ["creator", "creators"].includes(location.pathname.split("/")[1])
    ) {
      setActiveTab("CREATORS");
    }
  };

  let handleActiveTab = () => {
    let accountRoute = location.pathname.includes("/account");
    if (quickBitesActiveTabs.includes(location.pathname.split("/")[1])) {
      setActiveTab("QUICK_BITES");
    } else if (
      reportActiveTabs.includes(location.pathname.split("/")[1]) ||
      location.pathname === "/"
    ) {
      // setActiveTab("REPORT");
      setActiveTab("DASHBOARD");
    } else if (accountRoute) {
      setActiveTab("ACCOUNT_SETTING");
      // now I need to add another check for creator, creators route
    } else if (
      ["creator", "creators"].includes(location.pathname.split("/")[1])
    ) {
      setActiveTab("CREATORS");
    }
  };

  function getScreenWidth() {
    return (
      window.innerWidth ||
      document.documentElement.clientWidth ||
      document.body.clientWidth
    );
  }

  function handleItems() {
    if (getScreenWidth() > 768) {
      return Items;
    } else {
      return MobileItems;
    }
  }

  return (
    <>
      <div className="sidebar fixed top-0 left-0 flex flex-col items-center h-[100vh] w-[42px] md:w-[62px] pt-4 gap-y-2 md:gap-y-[15px]">
        {handleItems().map((item) => (
          <div
            onClick={() => handleNavigator(item.route)}
            className="w-[100%] flex"
          >
            <div
              className={`active-section w-[4px] h-full ${
                activeTab == item.name
                  ? "block bg-gradient-to-b from-purple-500 via-[#8254F8] to-purple-500 bg-clip-border"
                  : "hidden"
              }`}
            ></div>
            <div
              data-tooltip-id={item.id}
              data-tooltip-content={item.tooltipContent}
              className={`relative sidebar-icon mx-auto w-[36px] h-[36px] md:w-[48px] md:h-[48px] cursor-pointer rounded-full flex bg-[#353B4B] justify-center items-center ${
                item.name == "DASHBOARD" ? "" : ""
              } ${activeTab == item.name ? "" : ""}`}
            >
              <Tooltip id={item.id} />
              {!item.icon ? (
                <img
                  style={{ height: item.height, width: item.width }}
                  src={item.img}
                  alt="Logo"
                />
              ) : (
                item.img
              )}
              {item.new ? (
                <div className="absolute top-1 right-0 bg-gradient-to-r from-purple-500 to-indigo-500 text-white text-xs font-normal px-2 py-0.5 rounded-lg transform translate-x-1/2 -translate-y-1/2 shadow-lg">
                  New
                </div>
              ) : null}
            </div>
          </div>
        ))}
      </div>
    </>
  );
}

// export { Sidebar };
