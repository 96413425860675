import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useQuestionStore } from "../../store/question/question";
import { CopyBlock, dracula } from "react-code-blocks";
import { IoArrowBack } from "react-icons/io5";
import Logo from "../../assets/png/icon.png";
import { Button } from "../Button/Button";
import { useQuestionAnswerStore } from "../../store/questionAnswer/questionAnswer";
import { useQuizReportStore } from "../../store/quizAnswer/quizAnswer";
import {
  quizTypeConstant,
  resourceTypeConstantMapping,
} from "../../config/constants/quiz";
import { useQuizStore } from "../../store/quiz/quiz";
import { QuizProfileCard } from "../QuizProfileCard/QuizProfileCard";
import { QuizLeaderboard } from "../QuizLeaderboard/QuizLeaderboard";
import Loader from "../../assets/gif/buttonLoader.gif";
import { formatTime } from "../../config/helpers/moment-helper";
import { ReferenceTag } from "../ReferenceTag/ReferenceTag";
import { GrLinkedinOption } from "react-icons/gr";
import { useAccountStore } from "../../store/account/account";
import { SetToLocalStorage } from "../../config/helpers/local-storage-accessor-helper";
import { PostTypeEnum } from "../../config/constants/linkedIn";
import ReactMarkdown from "react-markdown";
import { Avatar } from "../Avatar/Avatar";
import { useAuthStore } from "../../store/auth/auth";
import { TiArrowBackOutline } from "react-icons/ti";

const Question = (props) => {
  let { fetchQuestionsByQuiz, questions, quiz, quizMaterial } =
    useQuestionStore();
  let { quizCategory, fetchQuizByCategory } = useQuizStore();
  let { saveQuestionAnswers } = useQuestionAnswerStore();
  let { saveQuizReport } = useQuizReportStore();

  let { id } = useParams();
  let navigate = useNavigate();

  const [time, setTime] = useState(0);
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isReadingDone, setIsReadingDone] = useState(false);
  const [isQuizCompleted, setIsQuizCompleted] = useState(false);
  const [quizResult, setQuizResult] = useState("");

  const [incorrectAnswers, setIncorrectAnswers] = useState("");
  const [correctAnswers, setCorrectAnswers] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setTime((prevTime) => prevTime + 1);
    }, 1000);

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    fetchQuizQuestions();
  }, []);

  let fetchQuizQuestions = async () => {
    try {
      setLoading(true);
      await fetchQuestionsByQuiz(id);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  let handleManageOptions = (data) => {
    let questionResponseAlreadyThere = options.filter(
      (option) => option.QuestionId !== data.QuestionId
    );
    console.log(questionResponseAlreadyThere, options);
    setOptions([...questionResponseAlreadyThere, data]);

    // if(questionResponseAlreadyThere){
    //   let filteredOptions = options.filter(option => option.QuestionId !== data.QuestionId)
    //   setOptions([...options, data])
    // }else{
    //   setOptions([...options, data])
    // }
  };

  // const formatTime = (seconds) => {
  //   const minutes = Math.floor(seconds / 60);
  //   const remainingSeconds = seconds % 60;
  //   return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
  // };

  let handleSubmit = async () => {
    try {
      let incorrectAnswers = options
        .filter((data) => !data.IsResponseCorrect)
        .map((data) => ({
          ...questions.find((question) => question._id === data.QuestionId),
          SelectedOption: data.Response,
        }));
      setIncorrectAnswers(() => incorrectAnswers);
      setButtonLoading(true);
      let currentTime = time;
      let SaveAnswersMapping = options.map((data) => saveQuestionAnswers(data));
      let correctAnswers = options.filter(
        (option) => option.IsResponseCorrect
      ).length;
      setCorrectAnswers(correctAnswers);
      await Promise.all(SaveAnswersMapping);
      let quizReportPayload = {
        QuizId: id,
        QuizCategoryId: quizCategory._id,
        NumberOfInCorrectAnswers: options.length - correctAnswers,
        TimeToComplete: currentTime,
        NumberOfCorrectAnswers: correctAnswers,
        QuizType: quizTypeConstant.QUIZ,
      };
      let QuizResponse = await saveQuizReport(quizReportPayload);
      setQuizResult(QuizResponse);
      setIsQuizCompleted(true);
      // navigate(`/quiz/${quizCategory._id}`)
    } catch (error) {
      console.log(error);
    } finally {
      setButtonLoading(false);
    }
  };

  const handleBack = () => {
    navigate(`/quiz/${quizCategory._id}`);
  };

  const handleReadingStuff = () => {
    setIsReadingDone(true);
  };
  return (
    <div className=" max-w-[1280px] mx-auto grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 px-2 md:px-10 gap-5">
      <div className="hidden md:col-span-1 md:flex flex-col justify-between h-[350px]">
        <QuizProfileCard />
      </div>
      {/*  */}
      {!isQuizCompleted ? (
        <div className="col-span-2 bg-[#252836] w-full h-[85vh] overflow-hidden rounded-xl flex flex-col">
          {!loading ? (
            <>
              <div className="header p-3 flex justify-between items-center">
                <div className="flex items-start gap-x-2">
                  <div className="">
                    <div className="text-md md:text-lg font-semibold text-white">
                      <span
                        onClick={handleBack}
                        className="cursor-pointer hover:text-[#8254F8] transition-colors duration-300"
                      >
                        {quizCategory?.Category}
                      </span>
                      <span className="text-gray-400">
                        {" "}
                        /{" "}
                        {quiz?.Heading.length > 30
                          ? quiz?.Heading.substring(0, 27) + "..."
                          : quiz?.Heading}
                      </span>
                    </div>
                    <div className="hidden md:block text-[12px]">
                      {quiz?.LearningMaterial?.length > 60
                        ? quiz?.LearningMaterial?.substring(0, 60) + "..."
                        : quiz?.LearningMaterial}
                    </div>
                  </div>
                </div>
                <div className="hidden sm:flex justify-end flex-col items-end">
                  <div className="text-lg font-semibold text-white">
                    {formatTime(time)}
                  </div>
                  <div className="text-[12px] font-semibold">
                    Assessment: {options?.length}/{questions?.length}
                  </div>
                </div>
              </div>
              <Separator />
              {isReadingDone ? (
                <div className="flex-grow overflow-y-auto scroll-bar py-3">
                  <div
                    onClick={() => setIsReadingDone(false)}
                    className="px-5 my-3 text-md text-white font-normal flex items-center gap-x-1 hover:underline cursor-pointer"
                  >
                    <TiArrowBackOutline className="text-2xl" />{" "}
                    <span className="text-xs"> Back to Learning </span>
                  </div>
                  {questions.map((data) => (
                    <QuestionStatement
                      questionInfo={data}
                      handleManageOptions={handleManageOptions}
                    />
                  ))}
                  <div className="w-[100%] flex justify-end px-5 py-3">
                    <Button
                      onClick={handleSubmit}
                      loading={buttonLoading}
                      buttonText={"Submit"}
                      isActive={
                        options.length === questions.length && !buttonLoading
                      }
                      disabled={
                        options.length !== questions.length || buttonLoading
                      }
                    />
                  </div>
                </div>
              ) : (
                <div className="flex flex-col h-full">
                  <LearningMaterial
                    quizMaterial={quizMaterial}
                    heading={quiz?.Heading}
                    handleReadingStuff={handleReadingStuff}
                  />
                </div>
              )}
            </>
          ) : (
            <div className="w-full h-full flex justify-center items-center">
              <img src={Loader} />
            </div>
          )}
        </div>
      ) : (
        <div className="col-span-3 md:col-span-2 bg-[#252836] w-full p-3 sm:p-10 overflow-hidden rounded-xl flex justify-center items-center">
          <QuizResultCard
            message={quizResult}
            incorrectAnswers={incorrectAnswers} // array
            correctAnswers={correctAnswers} // length
            quizCategoryId={quizCategory._id}
          />
        </div>
      )}
      {/*  */}
      <div className="hidden lg:flex col-span-4 md:col-span-1 h-[350px] flex-col justify-between">
        <QuizLeaderboard />
      </div>
    </div>
  );
};

const LearningMaterial = ({ quizMaterial, heading, handleReadingStuff }) => {
  return (
    <div className="flex flex-col h-full pb-[75px]">
      <div
        style={{ height: "inherit" }}
        className="overflow-y-auto scroll-bar p-3 bg-[#252836] rounded-xl flex flex-col justify-between flex-grow transform transition-all duration-300"
      >
        <div>
          {/* Heading */}
          <h3 className="text-lg font-bold text-white mb-3">{heading}</h3>

          {/* Paragraph */}

          <p className="text-sm markdown overflow-x-hidden font-normal text-white leading-relaxed mb-4">
            <ReactMarkdown children={quizMaterial?.Description} />
            {/* {quizMaterial?.Description} */}
            {/* {cleanAndFormatText('Relational Database Management Systems (RDBMS) are widely used for their ability to efficiently manage large volumes of structured data. One of the primary benefits of RDBMS is data integrity, which ensures that the data remains accurate and consistent over its lifecycle. RDBMS employs constraints, such as primary keys and foreign keys, to enforce rules on the data, preventing anomalies and ensuring relationships between tables are maintained. Additionally, RDBMS supports ACID (Atomicity, Consistency, Isolation, Durability) properties, which are crucial for reliable transaction processing.\n\nAnother significant advantage of RDBMS is the use of SQL (Structured Query Language), a powerful language that allows users to perform complex queries, join tables, and manipulate data with ease. This makes RDBMS suitable for a wide range of applications, from small-scale projects to large enterprise systems.\n\nHowever, RDBMS also has its limitations. One major drawback is scalability; as data grows, performance can degrade, especially in systems that require complex joins or transactions involving large datasets. Additionally, RDBMS typically requires a fixed schema, which can make it challenging to adapt to changing data requirements without significant redesign.\n\nCost is another consideration, as RDBMS solutions can involve high licensing fees, maintenance costs, and the need for skilled database administrators to manage and optimize the system. Moreover, the complexity of design and implementation can pose challenges, particularly for organizations lacking expertise in database management. Understanding both the benefits and limitations of RDBMS is essential for making informed decisions about data management strategies')} */}
            {/* ... Other text content ... */}
          </p>
        </div>

        {/* References Section */}
        {quizMaterial?.Resources?.length > 0 && (
          <div className="mt-3">
            <h4 className="text-md font-semibold text-white mb-2">
              References:
            </h4>
            <ul className="list-disc pl-5 text-white">
              {quizMaterial.Resources.map((data) => (
                <li className="flex align-items-start gap-x-2 mt-2">
                  <ReferenceTag type={data.Type} />
                  <a
                    target="_blank"
                    href={data.Url}
                    className="text-sm hover:underline"
                  >
                    {data.Text || data.Url}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        )}

        {/* Button Section */}
        <div className="w-full flex justify-end px-5 mt-[30px]">
          <Button
            onClick={handleReadingStuff}
            buttonText={"Ready For Quiz"}
            isActive={true}
          />
        </div>
      </div>
    </div>
  );
};

const QuestionStatement = ({ questionInfo, handleManageOptions }) => {
  let { userProfilePicture } = useAuthStore();

  let [response, setResponse] = useState("");

  let handleOption = (questionResponse) => {
    setResponse(questionResponse);
    handleManageOptions({
      QuestionId: questionInfo._id,
      Response: questionResponse,
      IsResponseCorrect: questionInfo.CorrectOption == questionResponse,
    });
  };
  return (
    <>
      <div className="">
        <div className="px-3 flex items-start gap-x-2">
          <div className="w-[35px] h-[35px] flex-shrink-0">
            <img className="" src={Logo} alt="logo" />
          </div>
          <div className="font-normal mt-1">
            <div className="text-lg">Discoursefy Bot</div>
            <div className="text-sm my-1 text-gray-300">
              {/* {questionInfo?.Question} */}
              <ReactMarkdown children={questionInfo?.Question} />
            </div>
            {questionInfo?.Code && (
              <CopyBlock
                text={questionInfo?.Code}
                showLineNumbers={true}
                theme={dracula}
                wrapLines={true}
              />
            )}
          </div>
        </div>
      </div>

      {/* options: */}
      <div className="my-3">
        <div className="px-3 flex items-start gap-x-2">
          {/* <div className="w-[35px] h-[35px] rounded-full bg-[#8254F8] flex-shrink-0"></div> */}
          <Avatar
            userProfilePicture={userProfilePicture}
            classes="w-[35px] h-[35px] rounded-full text-xl"
          />
          <div className="text-lg font-normal w-full mt-1">
            <div className="">You</div>
            <div className="grid grid-cols-1 text-sm my-2 text-gray-300 mt-4 w-[100%] gap-y-2">
              {questionInfo.Options.map((data) => (
                // <div
                //   onClick={() => handleOption(data)}
                //   className={`rounded-lg w-[100%] border-[#8254F8] border-[1px] py-3 text-center cursor-pointer transition-colors duration-500 hover:bg-[#8254F8] ${
                //     response === data ? "bg-[#8254F8]" : ""
                //   }`}
                // >
                //   {data}
                // </div>
                <div className="flex items-start justify-start gap-x-2">
                  <div
                    onClick={() => handleOption(data)}
                    className="mt-[2px] cursor-pointer h-[15px] border-[#8254F8] border-[1px] w-[15px]  rounded-full flex justify-center items-center"
                  >
                    <div
                      className={`ml-[0.5px] h-[9px] w-[9px] rounded-full ${
                        response === data ? "bg-[#8254F8]" : ""
                      }`}
                    ></div>
                  </div>
                  <div
                    onClick={() => handleOption(data)}
                    className={` w-[100%] cursor-pointer transition-colors duration-500`}
                  >
                    {data}
                    {/* <ReactMarkdown children={data} /> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

// const QuizResultCard = ({ correctAnswers, time, performancePercentage }) => {
// const QuizResultCard = ({ message, incorrectAnswers }) => {
//   let { getLinkedInAuthUrl } = useAccountStore();

//   const { id } = useParams();

//   let handleLinkedInShare = async () => {
//     SetToLocalStorage({ QuizId: id, PostType: PostTypeEnum.QuickBites });
//     let linkedInAuthUrl = await getLinkedInAuthUrl();
//     window.location.href = linkedInAuthUrl;
//   };
//   return (
//     <>
//     {/* List all the Incorrect Answer's Questions, the SelectedOption as a report view */}
//     <div className="relative w-full flex items-center bg-gradient-to-r from-purple-600 to-blue-500 p-4 rounded-xl shadow-lg">
//       <div className="flex-grow flex flex-col justify-center text-white relative z-10">
//         <h3 className="text-2xl font-bold mb-2 shine">Result</h3>
//         <p className="text-sm font-normal shine">{message}</p>
//         <p className="text-sm font-normal mt-2 shine">
//           Sharing your performance with your connections on LinkedIn
//           demonstrates your passion for learning and helps you stand out in your
//           professional network. Let your connections see your dedication!
//         </p>
//         <div className="flex flex-col sm:flex-row justify-start items-center gap-x-3">
//           <div className="mt-3">
//             <div
//               onClick={handleLinkedInShare}
//               className="flex justify-center cursor-pointer items-end gap-x-2 bg-[#0077B5] text-white px-4 py-2 rounded-full shadow-sm hover:shadow-lg transition"
//             >
//               <GrLinkedinOption className="mb-1 text-xl" /> Share on LinkedIn
//             </div>
//           </div>

//           <div className="mt-3">
//             <Link
//               to="/quick-bites"
//               className="inline-block bg-white text-blue-600 px-4 py-2 rounded-full shadow-sm hover:shadow-lg transition"
//             >
//               <i className="fab fa-linkedin-in mr-2"></i> Go To Dashboard
//             </Link>
//           </div>
//         </div>
//       </div>
//       {/* <div className="absolute inset-0 bg-white bg-opacity-10 backdrop-filter backdrop-blur-lg rounded-xl"></div> */}
//     </div>
//     </>
//   );
// };

const QuizResultCard = ({
  message,
  incorrectAnswers,
  correctAnswers,
  quizCategoryId,
}) => {
  const { getLinkedInAuthUrl } = useAccountStore();
  const { id } = useParams();

  const handleLinkedInShare = async () => {
    try {
      SetToLocalStorage({ QuizId: id, PostType: PostTypeEnum.QuickBites });
      const linkedInAuthUrl = await getLinkedInAuthUrl();
      window.location.href = linkedInAuthUrl;
    } catch (error) {
      console.error("Failed to get LinkedIn Auth URL:", error);
      // Optionally, show an alert or notification here
    }
  };

  return (
    <div>
      {/* <div>
        {incorrectAnswers.length > 0 && (
          <div className="mt-4">
            <h4 className="text-lg font-semibold text-white">
              Incorrect Answers
            </h4>
            <ul className="list-disc pl-5 text-white">
              {incorrectAnswers.map((answer, index) => (
                <li key={index}>
                  {answer.Question}{" "}
                  <div className="text-red-500 font-semibold">
                    {answer.SelectedOption}
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div> */}
      <div className="grid grid-cols-3 gap-x-2">
        <div class="bg-gray-700 text-white px-2 py-4 sm:p-4 rounded-lg shadow-md flex flex-col-reverse sm:flex-row items-center sm:items-end justify-between">
          <h5 class="text-xs mb-1 font-semibold">Total Questions</h5>
          <p class="text-2xl font-bold text-right">
            {incorrectAnswers.length + correctAnswers}
          </p>
        </div>
        {/* Correct Answers Card */}
        <div class="bg-gray-700 text-white px-2 py-4 sm:p-4 rounded-lg shadow-md flex flex-col-reverse sm:flex-row items-center sm:items-end justify-between">
          <h5 class="text-xs mb-1 font-semibold">Correct Answers</h5>
          <p class="text-2xl font-bold text-right">{correctAnswers}</p>
        </div>
        {/* Percentage Card */}
        <div class="bg-gray-700 text-white px-2 py-4 sm:p-4 rounded-lg shadow-md flex flex-col-reverse sm:flex-row items-center sm:items-end justify-between">
          <h5 class="text-xs mb-1 font-semibold">Efficiency</h5>
          <p class="text-lg font-bold text-right">
            {(
              (correctAnswers / (incorrectAnswers.length + correctAnswers)) *
              100
            ).toFixed(0)}
            %
          </p>
        </div>
      </div>

      <div class="relative">
        {/* Incorrect Answers Section */}
        {incorrectAnswers.length > 0 && (
          <div class="mt-4 p-4 bg-gray-800 rounded-lg shadow-md">
            <h4 class="text-lg font-normal text-white mb-2">
              <span class="font-normal text-white">
                Incorrect Answers: {incorrectAnswers.length}
              </span>
            </h4>
            <ul class="list-none pl-5 text-white">
              {incorrectAnswers.map((answer, index) => (
                <li key={index} class="flex items-start mb-2">
                  <span class="text-white mr-2">•</span>
                  <div class="flex-grow">
                    <div class="text-white font-medium">{answer.Question}</div>
                    <div class="text-red-500 font-semibold">
                      Selected: {answer.SelectedOption}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      <div className="mt-4 relative w-full flex items-center bg-gradient-to-r from-purple-600 to-blue-500 p-4 rounded-xl shadow-lg">
        <div className="flex-grow flex flex-col justify-center text-white relative z-10">
          <h3 className="text-2xl font-bold mb-2 shine">Result</h3>
          <p className="text-sm font-normal shine">{message}</p>
          <p className="text-sm font-normal mt-2 shine">
            Sharing your performance with your connections on LinkedIn
            demonstrates your passion for learning and helps you stand out in
            your professional network. Let your connections see your dedication!
          </p>

          <div className="flex flex-col sm:flex-row justify-start items-center gap-x-3">
            <div className="mt-3">
              <button
                onClick={handleLinkedInShare}
                className="flex justify-center w-[250px] sm:w-fit items-end gap-x-2 bg-[#0077B5] text-white px-4 py-2 rounded-full shadow-sm hover:shadow-lg transition"
                aria-label="Share on LinkedIn"
              >
                <GrLinkedinOption className="mb-1 text-xl" /> Share on LinkedIn
              </button>
            </div>

            <div className="mt-3">
              <Link
                to={`/quiz/${quizCategoryId}`}
                className="inline-block w-[250px] sm:w-fit text-center bg-white text-blue-600 px-4 py-2 rounded-full shadow-sm hover:shadow-lg transition"
              >
                <i className="fab fa-linkedin-in mr-2"></i> Continue
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

Question.propTypes = {};

export { Question };
