import React, { useEffect, useState } from "react";
import debounce from "lodash/debounce";
import VideoIcon from "../../assets/svg/Video.svg";
import SearchBar from "../SearchBar/SearchBar";
import Dropdown from "../Dropdown/Dropdown";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { IsAuthenticated } from "../../config/helpers/local-storage-accessor-helper";
import { useAuthStore } from "../../store/auth/auth";
import { IoWallet } from "react-icons/io5";
import { CreateEducationModal } from "../Education/CreateEducationModal";
import { PaymentCard } from "../PaymentCard/PaymentCard";

const Header = (props) => {
  const { getAccountInfo } = useAuthStore();
  const [text, setText] = useState("");
  const [headerLeft, setHeaderLeft] = useState(48);
  const [showPaymentModal, setShowPaymentModal] = useState();

  const { pathname } = useLocation();
  let navigate = useNavigate();

  useEffect(() => {
    let isUserCredsExist = IsAuthenticated();
    if (!isUserCredsExist) {
      navigate("/auth/login");
      return;
    }
    getAccountInfo();

    window.scrollTo(0, 0);
    if (!pathname.includes("setting")) {
      setHeaderLeft(() => headerLeft);
    } else {
      setHeaderLeft(312);
      // setHeaderLeft(50)
    }
  }, [pathname]);

  const onChange = (e) => setText(e.target.value);
  return (
    <div
      className={`flex items-center justify-end px-5 py-2 h-min ${
        !pathname.includes("account") ? "header-width" : "setting-header-width"
      } fixed top-[0] right-0 z-50 bg-[#1F1D2B]`}
    >
      <PaymentCard
        show={showPaymentModal}
        onRequestClose={() => setShowPaymentModal(false)}
      />
      {/* will open future version */}
      {/* <div className="search-bar hidden md:block">
        <SearchBar data={text} onChange={onChange} placeholder={"Search"} />
      </div> */}
      <div className="right-side flex flex-row items-center justify-between gap-x-3 w-max">
        <div onClick={() => setShowPaymentModal(true)} className="">
          <IoWallet className={`text-[24px] text-[#4B4B53] cursor-pointer`} />
        </div>
        {/* <div className="video-icon">
          <Link to={`/account/discussion`}>
            <img
              className="w-[24px] h-[24px]"
              src={VideoIcon}
              alt="go to discussion"
            />
            </Link>
        </div> */}
        <div className="">
          <Dropdown />
        </div>
      </div>
    </div>
  );
};

export { Header };
