import React, { useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import Camera from "../../assets/svg/Camera.svg";
import Chat from "../../assets/svg/Chat.svg";
import Wallet from "../../assets/svg/Wallet.svg";
import { CiSettings, CiStar } from "react-icons/ci";
import { useLocation, useNavigate } from "react-router";
import { FaRegStar } from "react-icons/fa6";

const SettingBar = (props) => {
  const [state, setState] = useState();
  let navigate = useNavigate()
  const location = useLocation()

  
  const handleNavigator = (route)=>{
    navigate(route)
  }
  return (
    <div className="w-[250px] h-[100vh] bg-[#2E3036] py-[34px] fixed top-0 hidden md:block">
      <div className="mx-auto">
        <div className="font-light w-[180px] mx-auto text-[#B9BBC1] text-xs capitalize">
          ACCOUNT SETTINGS
        </div>
        {/* <div className="my-4">
        <Separator />
        </div> */}
        <div className="flex flex-col text-white font-light text-sm mt-5 gap-y-1">
          <div onClick={()=>handleNavigator('/account/meeting')} className={`${location.pathname.includes('/account/meeting') && 'bg-[#8254F8]'}`}>
            <span className="w-[180px] mx-auto py-2 flex items-center gap-x-2 cursor-pointer">
              {" "}
              <img className="w-[20px]" src={Camera} /> <span> Meetings </span>{" "}
            </span>
          </div>
          <div onClick={()=>handleNavigator('/account/discussion')} className={`${location.pathname.includes('/account/discussion') && 'bg-[#8254F8]'}`}>
            <span className="w-[180px] mx-auto py-2 flex items-center gap-x-2 cursor-pointer">
              {" "}
              <img className="w-[20px]" src={Chat} /> <span> Discussions </span>{" "}
            </span>
          </div>
          <div onClick={()=>handleNavigator('/account/ratings')} className={`${location.pathname.includes('/account/ratings') && 'bg-[#8254F8]'}`}>
            <span className="w-[180px] mx-auto py-2 flex items-center gap-x-2 cursor-pointer">
              {" "}
              <FaRegStar className="text-[21px] font-light" />{" "}
              <span> Ratings </span>{" "}
            </span>
          </div>
          <div className={`${location.pathname == '/account/earn' && 'bg-[#8254F8]'}`}>
            <span onClick={()=>handleNavigator('/account/earn')} className="w-[180px] mx-auto py-2 flex items-center gap-x-2 cursor-pointer">
              {" "}
              <img className="w-[20px]" src={Wallet} /> <span> Earn </span>{" "}
            </span>
          </div>
          <div onClick={()=>handleNavigator('/account/setting')} className={`${location.pathname.includes('/account/setting') && 'bg-[#8254F8]'}`}>
            <span className="w-[180px] mx-auto py-2 flex items-center gap-x-2 cursor-pointer">
              {" "}
              <CiSettings className="text-[23px] font-light" />{" "}
              <span> Setting </span>{" "}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

SettingBar.propTypes = {};

export { SettingBar };
