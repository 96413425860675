import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useZoomStore } from "../../store/zoom/zoom";
import { useParticipantsStore } from "../../store/participants/participants";
import { useDiscussionStore } from "../../store/discussion/discussion";
import { useAuthStore } from "../../store/auth/auth";
import { Navigate, useNavigate } from "react-router-dom";
import { useMeetingStore } from "../../store/meeting/meeting";

const MeetingSession = ({ features, handleClose }) => {
  let { uitoolkit } = useZoomStore();
  let { sessionToken } = useParticipantsStore();
  let { analyzedDiscussions } = useDiscussionStore();
  let { analyzeMeeting } = useMeetingStore();
  let { user } = useAuthStore();

  let navigate = useNavigate()

  useEffect(() => {
    let sessionElement = document.getElementById("zoom-session");
    // meetingConfig
    let meetingArgs = {
      videoSDKJWT: sessionToken,
      sessionName: analyzedDiscussions?.Title || analyzeMeeting.Title,
      userName: user.FirstName,
      sessionPasscode: "123123",
      features: [...features],
    };
    uitoolkit.joinSession(sessionElement, meetingArgs);
    uitoolkit.onSessionJoined(()=>{
    })

    uitoolkit.onSessionClosed(()=>{
      handleClose()
    })

    return () => {
      uitoolkit.offSessionJoined(()=>{
        console.log('session joined successfully');
      })
      uitoolkit.offSessionClosed(()=>{
        console.log('session closed successfully');
        // navigate('/')
      })
      uitoolkit.closeSession(sessionElement);
    };
  }, [uitoolkit]);

  return (
    <div style={{}}>
      <div id="zoom-session"></div>
    </div>
  );
};

MeetingSession.propTypes = {};

export { MeetingSession };
