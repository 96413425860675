import React, { useState } from "react";
import PropTypes from "prop-types";
import { MeetingComponent } from "../../components/MeetingComponent/MeetingComponent";

const Meetings = (props) => {
  const [state, setState] = useState();
  return (
    <div className=' md:w-full'>
      <MeetingComponent />
    </div>
  );
};

Meetings.propTypes = {};

export { Meetings };
