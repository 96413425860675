import "./App.css";
import { Button } from "./components/Button/Button";
import Sidebar from "./components/Sidebar/Sidebar";
import {
  createBrowserRouter,
  RouterProvider,
  useNavigate,
} from "react-router-dom";
import MainLayout from "./pages/layout-v1/MainLayout";

import { Layout } from "./pages/Layout/Layout";
import { Creator } from "./pages/Creator/Creator";
import { Home } from "./pages/Home/Home";
import { ToastContainer, toast } from "react-toastify";
import Auth from "./pages/Auth/Auth";

import "react-toastify/dist/ReactToastify.css";
import { SettingsLayout } from "./pages/SettingsLayout/SettingsLayout";
import { AccountSetting } from "./pages/AccountSetting/AccountSetting";
import { Meetings } from "./pages/Meetings/Meetings";
import { Discussion } from "./pages/Discussion/Discussion";
import { Earn } from "./pages/Earn/Earn";
import { MeetingDetail } from "./pages/MeetingDetail/MeetingDetail";
import { DiscussionDetail } from "./pages/DiscussionDetail/DiscussionDetail";
import { Verify } from "./pages/Verify/Verify";
import SignIn from "./pages/SignIn/SignIn";
import SignUp from "./pages/SignUp/SignUp";
import { AccountActivation } from "./pages/AccountActivation/AccountActivation";
import UserCustomize from "./pages/UserCustomize/UserCustomize";
import UserInfo from "./pages/UserInfo/UserInfo";

import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BecomePartner } from "./components/BecomeCreator/BecomeCreator";
import { Rating } from "./pages/Ratings/Rating";
import { VideoRecorder } from "./components/VideoRecorder/VideoRecorder";
import { CreatorInfo } from "./pages/CreatorInfo/CreatorInfo";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import { Payout } from "./pages/Payout/Payout";
import { QuestionPage, QuizPage } from "./pages/Question/Question";
import { QuizCategory } from "./pages/QuizCategory/QuizCategory";
import { Quiz } from "./pages/Quiz/Quiz";
import { WeeklyPerformance } from "./pages/WeeklyPerformace/WeeklyPerformace";
import { WeeklyInsights } from "./pages/WeeklyInsights/WeeklyInsights";

import "react-loading-skeleton/dist/skeleton.css";
import { SkeletonTheme } from "react-loading-skeleton";
import { Referral } from "./pages/Referral/Referral";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    name: "Home",
    children: [
      {
        path: "/",
        element: <WeeklyPerformance />,
      },
      {
        path: "/weekly-performance/:id",
        element: <WeeklyInsights />,
      },
      {
        path: "/quick-bites",
        element: <QuizCategory />,
      },
      {
        path: "/quiz/:id",
        element: <Quiz />,
      },
      {
        path: "/questions/:id",
        element: <QuestionPage />,
      },
      {
        path: "/creators",
        // children:
        //   [
        //     {
        //       path: "/pink",
        //       element: <div className='text-white'>Hi</div>
        //     }
        //   ]
        // ,
        element: <Home />,
      },
      {
        path: "/creator/:id",
        element: <Creator />,
      },
    ],
  },
  {
    path: "/:id",
    element: <CreatorInfo />,
  },
  {
    path: "/auth",
    element: <Auth />,
    children: [
      {
        path: "login",
        element: <SignIn />,
      },
      {
        path: "signup",
        element: <SignUp />,
      },
      {
        path: "account/activation",
        element: <AccountActivation />,
      },
    ],
  },
  {
    path: "/verify/:code",
    element: <Verify />,
  },
  {
    path: "/referrals",
    element: <Referral />,
  },
  {
    path: "/user-customize",
    element: <UserCustomize />,
  },
  {
    path: "/user-info",
    element: <UserInfo />,
  },
  {
    path: "account",
    element: <SettingsLayout />,
    children: [
      {
        path: "setting/configure",
        element: <AccountSetting />,
      },
      {
        path: "setting/payoutSetup",
        element: <Payout />,
      },
      {
        path: "setting",
        element: <BecomePartner />,
      },
      {
        path: "meeting/:id",
        element: <MeetingDetail />,
      },
      {
        path: "meeting",
        element: <Meetings />,
      },
      {
        path: "discussion/:id",
        element: <DiscussionDetail />,
      },
      {
        path: "discussion",
        element: <Discussion />,
      },
      {
        path: "ratings",
        element: <Rating />,
      },
      {
        path: "earn",
        element: <Earn />,
      },
    ],
  },
  {
    path: "test",
    element: <VideoRecorder />,
  },
]);

const AppWrapper = () => {
  return (
    <div className="scroll-bar">
      <SkeletonTheme baseColor="#391E57" highlightColor="#4B2B68">
        <ToastContainer />
        <RouterProvider router={router} />
      </SkeletonTheme>
    </div>
  );
};

export default AppWrapper;
