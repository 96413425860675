import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Separator } from "../Separator/Separator";
import { Button } from "../Button/Button";
import { Tag } from "../Tag/Tag";
import { Badge } from "../Badge/Badge";
import { useQuizStore } from "../../store/quiz/quiz";
import { useNavigate, useParams } from "react-router-dom";
import { useQuizCategoriesStore } from "../../store/quizCategory/quizCategory";
import { FaArrowRight } from "react-icons/fa6";
import { PiCoinsFill } from "react-icons/pi";
import { QuizProfileCard } from "../QuizProfileCard/QuizProfileCard";
import { QuizLeaderboard } from "../QuizLeaderboard/QuizLeaderboard";
import Loader from "../../assets/gif/buttonLoader.gif";

import CanvasJSReact from "@canvasjs/react-charts";
import { formatTime } from "../../config/helpers/moment-helper";
import { useAnalyticsStore } from "../../store/analytics/analytics";
import { useQuizProfileStore } from "../../store/quizProfile/quizProfile";
import { quizLevelModeConstantMapping } from "../../config/constants/quiz";
import { NoDataFoundCard } from "../NoDataFoundCard/NoDataFoundCard";

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const Quiz = (props) => {
  let { quizProfile } = useQuizProfileStore();
  let { quizCategories } = useQuizCategoriesStore();
  let { fetchQuizByCategory, quiz, quizCategory } = useQuizStore();
  let { fetchQuizComparisonByCategory, userPerformance, avgUserPerformance } =
    useAnalyticsStore();

  let [selectedCategory, setSelectedCategory] = useState(null);
  let [loading, setLoading] = useState(false);
  let [hasComparativeAnalysisFound, setHasComparativeAnalysisFound] =
    useState(true);
  let [hasResponsesAnalysisFound, setHasResponsesAnalysisFound] =
    useState(true);

  const [chartOptions, setChartOptions] = useState(null);
  const [pieChartOptions, setPieChartOptions] = useState(null);

  let { id } = useParams();
  let navigate = useNavigate();

  useEffect(() => {
    fetchQuizzes();
  }, []);

  let fetchQuizzes = async () => {
    setLoading(true);
    let { UserPerformance, AvgPerformance } =
      await fetchQuizComparisonByCategory(id);

    if (!AvgPerformance.length || !UserPerformance.length) {
      setHasComparativeAnalysisFound(false);
    }

    const chartOptions = {
      backgroundColor: "#252836",
      animationEnabled: true,
      height: 260,
      // title:{
      //   text: "Number of New Customers"
      // },
      axisY: {
        // title: "Number of Customers"
        labelFontColor: "#ffffff",
      },
      axisX: {
        // title: "Number of Customers"
        labelFontColor: "#ffffff",
        title: "Quizzes",
        titleFontColor: "#ffffff",
      },
      toolTip: {
        // shared: true
      },
      legend: {
        fontColor: "#ffffff",
      },
      data: [
        {
          type: "line",
          name: "Average Performance",
          color: "gray",
          showInLegend: true,
          dataPoints: AvgPerformance.map((data) => ({
            y: data.average,
            label: data.level,
          })),
        },
        {
          type: "line",
          name: "Your Performance",
          color: "#8254F8",
          showInLegend: true,
          dataPoints: UserPerformance.map((data) => ({
            y: data.average,
            label: data.level,
          })),
        },
      ],
    };

    setChartOptions(chartOptions);
    await fetchQuizByCategory(id);

    if (quizProfile?.CorrectAnswers + quizProfile?.InCorrectAnswers === 0) {
      setHasResponsesAnalysisFound(false);
    }

    const pieChartOptions = {
      animationEnabled: true,
      height: 260,
      backgroundColor: "#252836", // "light1", "dark1", "dark2"
      axisY: {
        // title: "Number of Customers"
        labelFontColor: "#ffffff",
      },
      legend: {
        fontColor: "#ffffff",
      },
      axisX: {
        // title: "Number of Customers"
        labelFontColor: "#ffffff",
      },
      data: [
        {
          type: "pie",
          labelColor: "#000000",
          indexLabel: "{label}: {y}%",
          startAngle: -90,
          dataPoints: [
            {
              y: (
                (quizProfile?.CorrectAnswers /
                  (quizProfile?.CorrectAnswers +
                    quizProfile?.InCorrectAnswers)) *
                100
              ).toFixed(2),
              label: "Correct Answers",
              indexLabelFontColor: "#8254F8",
              color: "#8254F8",
            },
            {
              y: (
                (quizProfile?.InCorrectAnswers /
                  (quizProfile?.CorrectAnswers +
                    quizProfile?.InCorrectAnswers)) *
                100
              ).toFixed(2),
              label: "Incorrect Answers",
              indexLabelFontColor: "gray",
              color: "gray",
            },
          ],
        },
      ],
    };

    setPieChartOptions(pieChartOptions);
    setLoading(false);
  };

  let handleNavigation = (id) => {
    navigate(`/questions/${id}`);
  };

  let handleGoBack = () => {
    navigate("/quick-bites");
  };

  let completedQuiz = () => {
    return `${quiz.filter((data) => data.Report).length}/${quiz.length}`;
  };
  return (
    <div className="max-w-[1280px] mx-auto grid grid-cols-3 lg:grid-cols-4 px-3 sm:px-10 gap-5">
      <div className="col-span-3 md:col-span-1 md:flex flex-col justify-between">
        <div className="hidden md:block h-[300px]">
          <QuizProfileCard />
        </div>
        {chartOptions && hasComparativeAnalysisFound && (
          <div className="p-3 rounded-xl bg-[#252836] mt-2">
            <CanvasJSChart
              className=""
              options={chartOptions}
              /* onRef={ref => this.chart = ref} */
            />
          </div>
        )}
        {!hasComparativeAnalysisFound && (
          <div className="h-[300px]">
            <NoDataFoundCard text="Jump in and start tracking! See how your progress measures up against others!" />
          </div>
        )}
      </div>
      {/* Quizzes levels here */}
      <div className="col-span-3 md:col-span-2 bg-[#252836] w-full h-[85vh] overflow-hidden rounded-xl flex flex-col shadow-lg">
        {!loading ? (
          <>
            <div className="header p-4 flex justify-start items-end">
              <div>
                <div className="text-lg font-semibold text-white">
                  <span
                    onClick={handleGoBack}
                    className="cursor-pointer hover:text-[#8254F8] transition-colors duration-300"
                  >
                    Categories
                  </span>
                  <span className="text-gray-400">
                    {" "}
                    / {quizCategory?.Category}
                  </span>
                </div>
                <div className="text-xs text-gray-400 mt-1">
                  Assessment: {completedQuiz()}
                </div>
              </div>
            </div>
            <Separator />
            <div className="flex-grow overflow-y-auto scroll-bar py-4 gap-y-4">
              {quiz.map((data) => (
                <div key={data._id} className="px-4">
                  <div className="flex justify-between items-start gap-x-4">
                    <div className="text-md font-normal mt-1 text-white">
                      <div className="flex items-start gap-x-2">
                        <span
                          className="cursor-pointer hover:text-[#8254F8] transition-colors duration-300"
                          onClick={() => handleNavigation(data._id)}
                        >
                          <span className="my-3">{data.Heading}</span>
                        </span>
                        <Badge
                          text={quizLevelModeConstantMapping[data.LevelMode]}
                          classes="w-[10px]"
                        />
                      </div>
                      <div className="hidden sm:block text-xs text-gray-400 mt-1">
                        {data.LearningMaterial.length > 60
                          ? data.LearningMaterial.substring(0, 60) + "..."
                          : data.LearningMaterial}
                      </div>
                    </div>
                    {data.Report ? (
                      <div className="flex flex-col justify-end items-center gap-y-1 text-white">
                        <div className="text-xl font-normal flex justify-end items-center gap-x-2">
                          <span className="font-semibold text-3xl">
                            +{data.Report.NumberOfCorrectAnswers}
                          </span>
                          <PiCoinsFill className="text-yellow-500" />
                        </div>
                      </div>
                    ) : (
                      <div
                        onClick={() => handleNavigation(data._id)}
                        className="hidden w-[30px] h-[30px] cursor-pointer rounded-full sm:flex justify-center items-center bg-[#8254F8] hover:bg-[#6A00F4] transition-colors duration-300"
                      >
                        <FaArrowRight className="text-white" />
                      </div>
                    )}
                  </div>
                  <div className="my-4">
                    <Separator />
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <div className="w-full h-full flex justify-center items-center">
            <img src={Loader} />
          </div>
        )}
      </div>
      {/*  */}
      <div className="hidden md:col-span-1 lg:flex flex-col justify-between">
        <div className="h-[300px]">
          <QuizLeaderboard />
        </div>
        {pieChartOptions && hasResponsesAnalysisFound ? (
          <div className="p-3 rounded-xl bg-[#252836] mt-2">
            <CanvasJSChart
              options={pieChartOptions}
              /* onRef={ref => this.chart = ref} */
            />
          </div>
        ) : null}
        {!hasResponsesAnalysisFound ? (
          <div className="h-[300px]">
            <NoDataFoundCard text="Get started!" />
          </div>
        ) : null}
      </div>
    </div>
  );
};

Quiz.propTypes = {};

export { Quiz };
